.container {
  max-width: 1440px;
  padding: 24px;
  margin: auto;
}

.col .row {
  margin-left: (-1 * $gutter-width / 2);
  margin-right: (-1 * $gutter-width / 2);
}

// Mixins to eliminate code repitition
@mixin reset-offset {
  margin-left: auto;
  left: auto;
  right: auto;
}

@mixin grid-classes($size, $i, $perc) {
  &.offset-#{$size}#{$i} {
    margin-left: $perc;
  }

  &.pull-#{$size}#{$i} {
    right: $perc;
  }

  &.push-#{$size}#{$i} {
    left: $perc;
  }
}

.row {
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  // Clear floating children
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  @media #{$small-and-up} {
    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");

      .col-sm-#{$i} {
        width: $perc;
        @include reset-offset;
        float: left;
        box-sizing: border-box;
        padding: 0 $gutter-width / 2;
        min-height: 1px;

        &[class*="push-"],
        &[class*="pull-"] {
          position: relative;
        }
      }

      $i: $i+1;
    }

    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      @include grid-classes("col-sm-", $i, $perc);
      $i: $i + 1;
    }
  }

  @media #{$medium-and-up} {
    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");

      .col-md-#{$i} {
        width: $perc;
        @include reset-offset;
        float: left;
        box-sizing: border-box;
        padding: 0 $gutter-width / 2;
        min-height: 1px;

        &[class*="push-"],
        &[class*="pull-"] {
          position: relative;
        }
      }

      $i: $i+1;
    }

    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      @include grid-classes("col-md-", $i, $perc);
      $i: $i + 1;
    }
  }

  @media #{$large-and-up} {
    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");

      .col-lg-#{$i} {
        width: $perc;
        @include reset-offset;
        float: left;
        box-sizing: border-box;
        padding: 0 $gutter-width / 2;
        min-height: 1px;

        &[class*="push-"],
        &[class*="pull-"] {
          position: relative;
        }
      }

      $i: $i+1;
    }

    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      @include grid-classes("col-lg-", $i, $perc);
      $i: $i + 1;
    }
  }

  @media #{$extra-large-and-up} {
    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");

      .col-xl-#{$i} {
        width: $perc;
        @include reset-offset;
        float: left;
        box-sizing: border-box;
        padding: 0 $gutter-width / 2;
        min-height: 1px;

        &[class*="push-"],
        &[class*="pull-"] {
          position: relative;
        }
      }

      $i: $i+1;
    }

    $i: 1;

    @while $i <=$num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      @include grid-classes("col-xl-", $i, $perc);
      $i: $i + 1;
    }
  }
}
