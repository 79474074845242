@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// Inline flex display
@mixin inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex($values) {
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin abs-position($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin margin-padding($m-direction, $m-amount, $p-direction, $p-amount) {
  @if $m-direction==all {
    margin: $m-amount;
  } @else {
    margin-#{$m-direction}: $m-amount;
  }

  @if $p-direction==all {
    padding: $p-amount;
  } @else {
    padding-#{$p-direction}: $p-amount;
  }
}

/* breakpoints */

@mixin breakpoint($point) {
  @if $point==mac {
    @media (max-width: 1440px) {
      @content;
    }
    /* 1440 px*/
  }

  @if $point==large {
    @media (max-width: 1200px) {
      @content;
    }
    /* 1024 px*/
  } @else if $point==medium {
    @media (max-width: 992px) {
      @content;
    }

    /* 991 px*/
  } @else if $point==small {
    @media (max-width: 768px) {
      @content;
    }

    /* 767 px*/
  } @else if $point==x-small {
    @media (max-width: 576px) {
      @content;
    }

    /* 480 px*/
  } @else if $point==iphone {
    @media (max-width: 375px) {
      @content;
    }

    /* 375 px*/
  }
}
