html {
  font-size: 62.5%;
}

* {
  font-family: $font-regular;
}

body {
  background-color: color("neutral", "neutral-10");
  color: color("neutral", "neutral-60");
  font-family: $font-regular;
}

.text-light {
  color: color("neutral", "neutral-30");
}

.dots {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a {
  font-family: $font-medium;
  color: color("blue", "base");
  font-size: $body-small;
  text-decoration: none;
}

p {
  color: color("neutral", "neutral-60");
  line-height: 140%;
  font-size: $body-small;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.gap-buttons {
  gap: 8px;
}

.no-margin {
  margin: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-bold {
  font-family: $font-bold !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

input:focus {
  outline: 0;
}

.flex {
  @include flexbox;
}

input,
select {
  color: color("grey", "lighten-2") !important;
}

label,
mat-label {
  font-size: 1.6rem;
  color: #434343;
  display: block;
  font-family: $font-medium;
}

.mat-mdc-text-field-wrapper {
  background-color: transparent !important;
  padding: 0 !important;

  .mat-mdc-form-field-infix {
    padding: 0 !important;
  }
}

.custom-textarea {
  width: 100%;

  &>div+div {
    display: none;
  }
}

.mat-mdc-form-field textarea {
  &::placeholder {
    color: color("neutral", "neutral-30") !important;
  }

  background-color: color("neutral", "neutral-10");
  border: 1px solid color("neutral", "neutral-20") !important;
  border-radius: $button-radius-default;
  padding: 1.6rem !important;
  font-size: $body-small !important;
  width: 100%;
  color: color("neutral", "neutral-30") !important;
}

.mat-pseudo-checkbox {
  display: none !important;
}

mat-form-field.mat-mdc-form-field {
  // .mdc-text-field--filled {
  //   background-color: color("neutral", "neutral-10");
  //   border: 1px solid color("neutral", "neutral-20");
  //   border-radius: $button-radius-default;

  //   .mat-mdc-form-field-infix {
  //     width: 94px;
  //   }
  // }

  &.transparent-dropdown {
    .mdc-text-field--filled {
      border-radius: 0;
    }

    .mat-mdc-form-field-infix {
      width: 120px;
    }

    .mdc-text-field--filled {
      background-color: transparent;
      border: none;
      padding: 0;

      &+div {
        display: none;
      }
    }

    .mat-mdc-form-field-infix {
      padding: 0;
      height: 24px;
      min-height: 24px;
    }
  }
}

body {

  mat-label,
  .mat-mdc-select-arrow,
  .mat-mdc-select-trigger,
  .mat-mdc-menu-item,
  .mat-mdc-menu-item:visited,
  .mat-mdc-menu-item:link,
  .mat-mdc-menu-item .mat-icon-no-color,
  .mat-mdc-menu-item .mat-mdc-menu-submenu-icon,
  .mat-mdc-option .mdc-list-item__primary-text {
    color: color("neutral", "neutral-50") !important;
    font-size: $body-small;
  }

  .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    font-size: $body-small;
  }

  .mat-mdc-menu-item .mat-icon {
    height: 20px;
    width: 20px;
    font-size: 20px;
  }

  .mat-mdc-option {
    min-height: 32px;
  }

  .mat-mdc-option .mdc-list-item__primary-text {
    font-size: $body-small;
  }

  .mat-mdc-menu-panel,
  div.mat-mdc-select-panel {
    background-color: color("neutral", "neutral-20");
    border: 1px solid color("neutral", "neutral-20");
  }
}
