.button-primary {

  &:hover,
  &:focus {
    background-color: color("neutral", "neutral-20");
    border-color: color("neutral", "neutral-30");
    color: color("shades", "white");
  }

  padding: $small-button-padding;
  background-color: color("neutral", "neutral-10");
  border: 1px solid color("neutral", "neutral-20");
  color: color("neutral", "neutral-50");
  border-radius: $button-radius-default;
  font-size: $body-small;
  display: inline-flex;
  gap: 4px;
  height: 40px;
  align-items: center;

  img,
  mat-icon {
    width: 2rem;
    height: 2rem;
    font-size: 2rem;
  }

  &.white {

    &:hover,
    &:focus {
      background-color: color("shades", "white");
      border-color: color("shades", "white");
    }

    background-color: color("neutral", "neutral-60");
    color: color("neutral", "neutral-10");
  }

  @include breakpoint(x-small) {
    padding: $small-button-padding;
  }
}

.button-link {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: $body-medium;
  display: inline-flex;
  align-items: center;
  padding: 0;

  &:hover {
    color: color("blue", "base");
  }
}

.large-button {
  padding: $large-button-padding !important;
}

.small-button {
  padding: $small-button-padding !important;
  font-size: 1.4rem !important;
}
