@font-face {
  font-family: $font-regular;
  src: url(../../fonts/Manrope-Regular.ttf);
}

@font-face {
  font-family: $font-medium;
  src: url(../../fonts/Manrope-Medium.ttf);
}

@font-face {
  font-family: $font-semibold;
  src: url(../../fonts/Manrope-Semibold.ttf);
}

@font-face {
  font-family: $font-bold;
  src: url(../../fonts/Manrope-Bold.ttf);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-semibold;
  color: color("grey", "lighten-1");
  font-weight: 600;
}

p {
  font-family: $font-regular;
}

.pre-heading {
  font-size: $body-medium;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: $font-bold;

  @include breakpoint(small) {
    font-size: $small-cap;
  }
}

.small-cap {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: $font-bold;
  font-size: $small-cap;
}

.text-medium {
  font-family: $font-medium;
}

.body-large {
  font-size: $body-large !important;

  @include breakpoint(small) {
    font-size: $body-medium !important;
  }
}

.body-large-medium {
  font-family: $font-medium;
  font-size: $body-large;
  color: color("grey", "lighten-2");
  line-height: 150%;

  @include breakpoint(small) {
    font-size: $body-medium;
  }
}

// Header Styles
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: inherit;
}

h1 {
  font-size: $h1-fontsize;
  line-height: 120%;

  @include breakpoint(medium) {
    font-size: $h2-fontsize;
  }
}

h2,
.display-2 {
  font-size: $h2-fontsize;
  line-height: 120%;

  @include breakpoint(small) {
    font-size: $h3-fontsize;
  }
}

h3,
.display-3 {
  font-size: $h3-fontsize;
  line-height: 120%;

  @include breakpoint(small) {
    font-size: $h4-fontsize;
  }
}

h4,
.display-4 {
  font-size: $h4-fontsize;
  line-height: 120%;
}

h5,
.display-5 {
  font-size: $h5-fontsize;
  line-height: 120%;
}

h6 {
  font-size: $h6-fontsize;
  line-height: 120%;
}

// Text Styles
em {
  font-style: italic;
}

strong {
  font-weight: 500;
}

small {
  font-size: 75%;
}

.light {
  font-weight: 300;
}

.thin {
  font-weight: 200;
}
