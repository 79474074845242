  .main-content {
    flex: 0 0 60%;

    .mat-accordion {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .mat-expansion-panel-spacing {
      margin: 0;
    }

    .mat-content {
      flex-direction: column;
    }

    .mat-expansion-panel {
      background-color: rgba(255, 255, 255, 0.03);
      border: 1px solid color("neutral", "neutral-20");
      border-radius: $button-radius-default !important;
    }

    .mat-expansion-panel-header-title {
      justify-content: space-between;
      color: color("shades", "white");
      margin: 0 0 0 16px;
    }

    .mat-expansion-panel-header {
      flex-direction: row-reverse;
      height: 64px;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      background-color: rgba(255, 255, 255, 0.03);
    }

    .timer-text {
      font-size: $body-medium;
    }

    .mat-expansion-indicator::after {
      color: color("neutral", "neutral-50");
    }

    .mat-expansion-panel-body {
      padding: 1.6rem;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .accordion-buttons {
      display: flex;
      gap: .8rem;
    }

    .accordion-content {
      display: flex;
      width: 100%;
      gap: .8rem;
      background-color: rgba(255, 255, 255, 0.04);
      padding: 1.6rem;
      border-radius: $button-radius-default;

      &.active {
        background-color: color("primary", "base");

        .count {
          color: color("shades", "white");
          background-color: rgba(255, 255, 255, 0.15);
        }

        .duration * {
          color: color("shades", "white");
        }
      }
    }

    .count {
      width: 60px;
      height: 60px;
      flex: 0 0 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $body-large;
      color: color("neutral", "neutral-50");
      background-color: color("neutral", "neutral-20");
      border-radius: $button-radius-default;
    }

    .content-bottom {
      display: flex;
      color: color("neutral", "neutral-30");
      gap: 1.6rem;
      align-items: flex-end;

      p {
        color: color("neutral", "neutral-30");
      }

      .mat-mdc-select-trigger,
      .mat-mdc-select-arrow {
        color: color("neutral", "neutral-30") !important;
      }
    }

    .timer-content {
      display: flex;
      flex-direction: column;
      width: 100%;

      .name {
        display: flex;
        gap: .4rem;
        align-items: center;

        .mat-icon {
          font-size: 2rem;
        }
      }

      .time {
        display: flex;
        align-items: center;

        .mat-mdc-form-field {
          margin-left: .8rem;
        }

        .mat-icon {
          width: 18px;
          height: 18px;
          font-size: 18px;
          margin-right: 4px;
        }
      }
    }

    .display-4 {
      margin-bottom: .8rem;
    }

    .duration-text {
      margin-bottom: 4px;
    }

    .notes span,
    .speaker span {
      width: 110px;
    }

    .speaker span {
      width: 112px;
    }

    .timer-buttons {
      display: flex;
      gap: 8px;
    }

    .message-outer {
      display: flex;
      flex-direction: column;
    }

    .message-actions {
      display: flex;
      justify-content: space-between;
    }

    .connected-devices {
      margin-top: 16px;

      .accordion-content {
        padding-top: 4px;
        padding-bottom: 4px;
        flex-direction: column;
      }

      .device-name {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
      }
    }
  }
