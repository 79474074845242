$primary: ("base": #5B70FA,
);

$neutral: ("neutral-10": #0B0B0F,
  "neutral-20": #252833,
  "neutral-30": #7E8095,
  "neutral-40": #959393,
  "neutral-50": #E5E5E5,
  "neutral-60": #F5F5F5,
);

$validation: ("information": #0074BD,
  "success": #0E8127,
  "warning": #BD4B00,
  "error": #C72623,
);

$shades: ("black": #000000,
  "white": #ffffff,
  "transparent": transparent,
);

$colors: ("primary": $primary,
  "neutral": $neutral,
  "validation": $validation,
  "shades": $shades,
) !default;

@function color($color, $type) {
  @if map-has-key($colors, $color) {
    $curr_color: map-get($colors, $color);

    @if map-has-key($curr_color, $type) {
      @return map-get($curr_color, $type);
    }
  }

  @return null;
}

.green {
  color: #16A34A !important;
}

.red {
  color: #EF0000 !important;
}
