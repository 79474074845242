/* Main page code starts */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .working {
    display: inline-flex;
    align-items: center;
    gap: 32px;
  }

  .actions-right {
    display: flex;
    gap: 8px;
  }
}

.main-content {
  display: flex;
  gap: 24px;

  .right-content {
    flex: 0 0 calc(40% - 36px);
  }

  .right-outer-main {
    border: 1px solid color("neutral", "neutral-20");
    border-radius: $button-radius-default;

    .right-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      height: 64px;
      gap: 16px;
      background-color: rgba(255, 255, 255, 0.03);

      .timer-name {
        width: 174px;
      }

      .timer-buttons {
        display: flex;
        gap: .5rem;
      }
    }

    .right-timer {
      padding: 16px;

      .logo {
        width: 60px;
      }
    }

    .main-timer {
      position: relative;
      text-align: center;

      &:hover {
        .chart-outer .gap-buttons {
          display: flex;
        }
      }

      .gap-buttons {
        margin-top: 24px;
      }

      .chart-outer {
        position: relative;

        .gap-buttons {
          position: absolute;
          width: 196px;
          height: 80px;
          left: 0;
          right: 0;
          margin: auto;
          align-items: center;
          justify-content: center;
          z-index: 9;
          background-color: rgba(0, 0, 0, 0.7);
          border-radius: $button-radius-default;
          backdrop-filter: blur(5px);
          top: 0;
          bottom: 0;
          display: none;
        }
      }
    }

    #timer {
      width: 230px !important;
      height: 230px !important;
      margin: auto;
    }

    .main-time,
    .main-message,
    .project-name,
    .speaker-name {
      position: absolute;
      top: 70px;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      width: 230px !important;
    }

    .main-message {
      top: 110px;
      padding: 0 30px;
    }

    .project-name,
    .speaker-name {
      font-size: 1.2rem;
      color: color("primary", "base");
      font-family: $font-medium;
    }

    .project-name {
      top: 25px;
      width: 130px !important;
    }

    .speaker-name {
      top: 190px;
      width: 130px !important;
    }

    .timezone {
      background-color: rgba(255, 255, 255, 0.04);
      padding: 1.6rem;
      border-radius: $button-radius-default;
      margin-top: 24px;
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 16px;
    }

    .timezone-top {
      display: flex;
      justify-content: space-between;

      .time-left {
        display: flex;
        font-size: $body-large;
        align-items: center;
        gap: 4px;
      }

      .location-name p {
        font-size: $body-medium;
      }
    }

    .timezone-bottom {
      display: flex;
      justify-content: space-between;

      p {
        font-size: $body-large;
      }

      .text-light {
        font-size: $body-small;
      }
    }
  }
}

/* Main page code ends */
