// ==========================================================================
// Variables
// ==========================================================================
//
// Table of Contents:
//
//  1. Badges
//  2. Buttons
//  3. Cards
//  4. Carousel
//  5. Collapsible
//  6. Chips
//  7. Date + Time Picker
//  8. Dropdown
//  9. Forms
//  10. Global
//  10. Grid
//  11. Navigation Bar
//  12. Side Navigation
//  13. Photo Slider
//  14. Spinners | Loaders
//  15. Tabs
//  16. Tables
//  17. Toasts
//  18. Typography
//  19. Footer
//  20. Progress Bar

// 1. Badges
// ==========================================================================

$badge-bg-color: color("grey", "lighten-2") !default;
$badge-height: 2.2rem !default;

// 2. Buttons
// ==========================================================================

// Shared styles
$button-border: none !default;
// $button-background-focus: lighten(color("grey", "lighten-5"), 4%) !default;
$button-font-size: 1.6rem !default;
$button-icon-font-size: 1.8rem !default;
$button-height: 3rem !default;
$button-padding: 1.25rem 2.4rem !default;
$button-circular-radius: 5rem !default;
$button-radius-default: 0.8rem;
$small-button-padding: 0.9rem 1.6rem;
$medium-button-padding: 0.7rem 5rem;
$large-button-padding: 1.3rem 6rem;

// Disabled styles
$button-disabled-background: color("grey", "lighten-2") !default;
$button-disabled-color: color("grey", "lighten-2") !default;

// Raised buttons
$button-raised-background: color("blue", "base") !default;
// $button-raised-background-hover: lighten(
//   $button-raised-background,
//   5%
// ) !default;
$button-raised-color: color("grey", "lighten-2") !default;

// Large buttons
$button-large-font-size: 2rem !default;
$button-large-icon-font-size: 2.4rem !default;
$button-large-height: $button-height * 1.5 !default;

// Flat buttons
$button-flat-color: color("grey", "lighten-2") !default;
// $button-flat-disabled-color: lighten(color("grey", "lighten-2"), 10%) !default;

// Floating buttons
$button-floating-background: color("grey", "lighten-2") !default;
$button-floating-background-hover: $button-floating-background !default;
$button-floating-color: color("grey", "lighten-2") !default;
$button-floating-size: 4rem !default;
$button-floating-large-size: 5.6rem !default;
$button-floating-radius: 50% !default;

// 3. Cards
// ==========================================================================

$card-padding: 1.7rem 2.5rem 2rem !default;
// $card-bg-color: color("grey", "lighten-2") !default;
$card-link-color: color("orange", "base") !default;
// $card-link-color-light: lighten($card-link-color, 20%) !default;

// 4. Carousel
// ==========================================================================

$carousel-height: 40rem !default;
$carousel-item-height: $carousel-height / 2 !default;
$carousel-item-width: $carousel-item-height !default;

// 5. Collapsible
// ==========================================================================

$collapsible-height: 3rem !default;
$collapsible-line-height: $collapsible-height !default;
$collapsible-header-color: color("grey", "lighten-2") !default;
$collapsible-border-color: color("grey", "lighten-2") !default;

// 6. Chips
// ==========================================================================

$chip-bg-color: color("grey", "lighten-2") !default;
$chip-border-color: color("grey", "lighten-2") !default;
$chip-selected-color: color("grey", "lighten-2") !default;
$chip-margin: 0.5rem !default;

// 7. Date + Time Picker
// ==========================================================================

$datepicker-display-font-size: 2.8rem;
$datepicker-calendar-header-color: color("grey", "lighten-2");
$datepicker-weekday-color: color("grey", "lighten-2") !default;
// $datepicker-weekday-bg: darken(color("grey", "lighten-2"), 7%) !default;
$datepicker-date-bg: color("grey", "lighten-2") !default;
$datepicker-year: color("grey", "lighten-2") !default;
$datepicker-focus: color("grey", "lighten-2") !default;
$datepicker-selected: color("grey", "lighten-2") !default;
// $datepicker-selected-outfocus: desaturate(
//   lighten(color("grey", "lighten-2"), 35%),
//   15%
// ) !default;
// $datepicker-day-focus: transparentize(
//   desaturate(color("grey", "lighten-2"), 5%),
//   0.75
// ) !default;
$datepicker-disabled-day-color: color("grey", "lighten-2") !default;

$timepicker-clock-color: color("grey", "lighten-2") !default;
$timepicker-clock-plate-bg: color("grey", "lighten-2") !default;

// 8. Dropdown
// ==========================================================================

$dropdown-bg-color: color("grey", "lighten-2") !default;
$dropdown-hover-bg-color: color("grey", "lighten-2") !default;
$dropdown-color: color("grey", "lighten-2") !default;
$dropdown-item-height: 5rem !default;

// 9. Forms
// ==========================================================================

// Text Inputs + Textarea
$input-height: 4.4rem !default;
$input-border-color: color("grey", "lighten-2") !default;
$input-border: 1px solid $input-border-color !default;
$input-background: color("grey", "lighten-2") !default;
$input-error-color: color("grey", "lighten-2") !default;
$input-success-color: color("grey", "lighten-2") !default;
$input-focus-color: color("grey", "lighten-2") !default;
$input-font-size: 1rem !default;
$input-margin-bottom: 0.8rem;
$input-margin: 0 0 $input-margin-bottom 0 !default;
$input-padding: 0 1.5rem !default;
$input-transition: all 0.3s !default;
$label-font-size: 1.6rem !default;
$input-disabled-color: color("grey", "lighten-2") !default;
$input-disabled-solid-color: color("grey", "lighten-2") !default;
$input-disabled-border: 1px dotted $input-border-color !default;
$input-invalid-border: 1px solid $input-error-color !default;
$placeholder-text-color: color("grey", "lighten-2") !default;

// Radio Buttons
$radio-fill-color: color("blue", "base") !default;
$radio-empty-color: color("grey", "lighten-2") !default;
$radio-border: 2px solid $radio-fill-color !default;

// Range
$range-height: 1.4rem !default;
$range-width: 1.4rem !default;
$track-height: 3px !default;

// Select
$select-border: 1px solid color("grey", "lighten-2") !default;
$select-background: color("grey", "lighten-2") !default;
// $select-focus: 1px solid lighten(color("grey", "lighten-2"), 47%) !default;
$select-option-hover: color("grey", "lighten-2") !default;
$select-option-focus: color("grey", "lighten-2") !default;
$select-option-selected: color("grey", "lighten-2") !default;
$select-padding: 0.5rem !default;
$select-radius: 2px !default;
$select-disabled-color: color("grey", "lighten-2") !default;

// Switches
$switch-bg-color: $radio-fill-color !default;
// $switch-checked-lever-bg: desaturate(
//   lighten($switch-bg-color, 25%),
//   25%
// ) !default;
// $switch-unchecked-bg: color("grey", "lighten-2") !default;
$switch-unchecked-lever-bg: color("grey", "lighten-2") !default;
$switch-radius: 1.5rem !default;

// 10. Global
// ==========================================================================

// Media Query Ranges
$extra-small-screen-up: 320px !default;
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;

$small-and-up: "only screen and (min-width : #{$extra-small-screen-up})" !default;
$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;

// 11. Grid
// ==========================================================================

$num-cols: 12 !default;
$gutter-width: 3rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width * 2)/3 !default;

// 12. Navigation Bar
// ==========================================================================

$navbar-height: 4.2rem !default;
$navbar-line-height: $navbar-height !default;
$navbar-height-mobile: 4.2rem !default;
$navbar-line-height-mobile: $navbar-height-mobile !default;
$navbar-font-size: 1.4rem !default;
$navbar-font-color: color("grey", "lighten-2") !default;
$navbar-brand-font-size: 2.1rem !default;

// 13. Photo Slider
// ==========================================================================

$slider-bg-color: color("grey", "base") !default;
$slider-bg-color-light: color("grey", "lighten-2") !default;
$slider-indicator-color: color("green", "base") !default;

// 14. Spinners | Loaders
// ==========================================================================

$spinner-default-color: color("grey", "lighten-2") !default;

// 15. Tabs
// ==========================================================================

$tabs-underline-color: color("grey", "lighten-2") !default;
$tabs-text-color: color("grey", "lighten-2") !default;
$tabs-bg-color: color("grey", "lighten-2") !default;

// 16. Tables
// ==========================================================================

$table-border-color: color("grey", "lighten-2") !default;
$table-striped-color: color("grey", "lighten-2") !default;

// 17. Toasts
// ==========================================================================

$toast-height: 4.8rem !default;
$toast-color: color("grey", "lighten-2") !default;
$toast-text-color: color("grey", "lighten-2") !default;
$toast-action-color: color("grey", "lighten-2");

// 18. Typography
// ==========================================================================

$font-regular: "Manrope Regular",
sans-serif;
$font-medium: "Manrope Medium",
sans-serif;
$font-semibold: "Manrope Semibold",
sans-serif;
$font-bold: "Manrope Bold",
sans-serif;
// Header Styles
$h1-fontsize: 3.9rem !default;
$h2-fontsize: 2.8rem !default;
$h3-fontsize: 2.4rem !default;
$h4-fontsize: 2rem !default;
$h5-fontsize: 1.6rem !default;
$h6-fontsize: 1.4rem !default;
$body-large: 1.8rem !default;
$body-medium: 1.6rem !default;
$body-small: 1.4rem !default;
$small-cap: 1.3rem !default;

// 19. Footer
// ==========================================================================

$footer-font-color: color("grey", "lighten-2") !default;
$footer-bg-color: color("grey", "lighten-2") !default;
$footer-copyright-font-color: color("grey", "lighten-2") !default;
$footer-copyright-bg-color: color("grey", "lighten-2") !default;

// 20. Progress Bar
// ==========================================================================

$progress-bar-color: color("grey", "lighten-2") !default;
